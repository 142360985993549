<script>
import appConfig from "@/app.config";
import { authEasyMethods, notificationMethods } from '@/state/helpers';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/page-header';
import List from "./list.vue";
import Insert from "./insert.vue";
import Edit from "./edit.vue";
//import { http } from '../../../../helpers/easyindustriaapi/config';
import Controller_unidade from './Controller_unidade';
export default {
  page: {
    title: 'Unidade de medida',
    meta: [{ name: 'description', content: appConfig.description }]
  },
  components: { Layout, PageHeader,Insert,List,Edit},
  data() {
    return {
      currentUnidade:{},
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Parâmetros",
          href: "/",
          active: true,
        },
        {
          text: "Unidade de Medida",
          href: "/parametros/unidademed",
          active: true,
        }
      ],
      Unidade: undefined,
      T_unidades: [],
      titleBody: 'Unidade de Medida',
      editando: false,
      inserindo: false,
    }
  },
  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user')); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));// para saber a emrpessa atual;
    // storage esta no navegador em aplucation;
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isHide() {
      return (!this.inserindo && !this.editando);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
     async getData() {
      this.T_unidades = await Controller_unidade.getData(
        this.currentEmpresa,
        this.onLoader,
        this.offLoader,
        this.makeToast
      );
    },
    setUnidade(Unidade) {
      this.currentUnidade = Unidade;
    },
    insert() {
      this.inserindo = !this.inserindo;
    },
    edit(oldUnidade) {
      this.setUnidade(oldUnidade);
      this.editando = !this.editando;
    },
    async doPost(unidade){  // codigo rafatorado; //  bem mais enxuto e legivel;
      const { getData, onLoader, offLoader, makeToast} = this;
      await Controller_unidade.doPost(unidade, this.currentEmpresa, this.currentUser, getData, onLoader, offLoader, makeToast);
      this.inserindo = false
    },
    async doPut(Unidade) {
      await Controller_unidade.doPut1(Unidade, this.currentEmpresa, this.currentUser, this.getData, this.onLoader, this.offLoader, this.makeToast);
      this.editando = false;
      this.getData();
    },
    async doDelete(Unidade) {
      await Controller_unidade.doDelete(Unidade, this.currentEmpresa, this.currentUser, this.getData, this.onLoader, this.offLoader, this.makeToast);
      this.getData();
    },
    back() {
      this.inserindo = false;
      this.editando  = false;
      this.titleBody = 'Unidade de Medida';
    },
  },
}
</script>

<template>
<Layout>
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir unidade</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div v-if="!isHide" class="card-body">
           <Edit v-if="editando"
           :empresa="currentEmpresa"
           :oldUnidade="currentUnidade"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />
          <Insert v-if="inserindo"
           @newTitle="changeHomeTitle"
           @doPost="doPost"
          />
        </div>
        <List
         v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listUnidades="T_unidades"
         :hide="isHide"
         @edit="edit"
         @newTitle="changeHomeTitle"
         @setUnidade="setUnidade"
         @doDelete="doDelete"
        />
      </div>
    </div>
  </div>
</Layout>
</template>